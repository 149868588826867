import { PrismicLink, PrismicProvider } from '@prismicio/react';
import Script from 'next/script';

import { useEffect, useRef } from 'react';
import NextApp from 'next/app';
import NextLink from 'next/link';
import React from 'react';

import { createClient } from '../prismicio';

import CookieConsent from '../components/CookieConsent';
import { Fonts } from '../components/Fonts';

import { generateLinkResolver } from '../helpers/link-resolver';
import { GlobalContextProvider } from '../helpers/context';
import { isLinkDynamicCompatible } from '../helpers/link';

import { pushDataLayerEvent } from '../helpers/data-layer';

import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import '../styles/index.css';

function App({ Component, pageProps, global }) {
  const first = useRef(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = {
        url: pageProps?.locale?.slug,
        page_type: pageProps?.page?.data?.specialType || 'page',
      };

      pushDataLayerEvent({
        event: 'page_view',
        ...params,
      });

      if (first.current) {
        pushDataLayerEvent({
          event: 'first_page_view',
          ...params,
        });

        first.current = false;
      }
    }
  }, [pageProps?.locale?.slug]);

  if (pageProps.locale) {
    useEffect(() => {
      document.documentElement.setAttribute(
        'lang',
        pageProps?.locale?.current.shortCode
      );
    }, [pageProps?.locale?.current]);
  }

  const linkResolver = generateLinkResolver({
    articles: global.articles,
    categories: global.categories,
    pages: global.pages,
  });

  const internalLinkComponent = ({ href, children, ...props }) => {
    const El = isLinkDynamicCompatible(href) ? NextLink : 'a';

    return (
      <El href={href} {...props}>
        {children}
      </El>
    );
  };

  const richTextComponents = {
    image: ({ node, key }) => {
      const img = (
        <img
          src={node.url}
          alt={node.alt ?? undefined}
          data-copyright={node.copyright ? node.copyright : undefined}
        />
      );

      return (
        <p key={key} className="block-img">
          {node.linkTo ? (
            <PrismicLink
              linkResolver={linkResolver}
              internalComponent={internalLinkComponent}
              field={node.linkTo}
            >
              {img}
            </PrismicLink>
          ) : (
            img
          )}
        </p>
      );
    },
    hyperlink: ({ node, children, key }) => (
      <PrismicLink
        key={key}
        field={node.data}
        linkResolver={linkResolver}
        internalComponent={internalLinkComponent}
      >
        {children}
      </PrismicLink>
    ),
  };

  return (
    <>
      {process.env.GTM_ID ? (
        <Script id="gtm" strategy="afterInteractive">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://sst.batman-escape.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.GTM_ID}');`}
        </Script>
      ) : null}

      <Fonts />

      <PrismicProvider
        linkResolver={linkResolver}
        internalLinkComponent={internalLinkComponent}
        richTextComponents={richTextComponents}
      >
        <GlobalContextProvider>
          <Component {...pageProps} />

          <CookieConsent pageProps={pageProps} />
        </GlobalContextProvider>
      </PrismicProvider>
    </>
  );
}

App.getInitialProps = async (appContext) => {
  const appProps = await NextApp.getInitialProps(appContext);

  const client = createClient();

  const pages = await client.getAllByType('page', { lang: '*' });
  const articles = await client.getAllByType('article', { lang: '*' });
  const categories = await client.getAllByType('category', { lang: '*' });
  const missions = await client.getAllByType('mission', { lang: '*' });
  const events = await client.getAllByType('event', { lang: '*' });

  const shared_slices = await client.getSingle('shared_slices', {
    lang: '*',
  });
  return {
    ...appProps,

    global: {
      pages: pages.map((page) => {
        return {
          id: page.id,
          lang: page.lang,
          data: {
            slug: page.data.slug,
          },
        };
      }),
      articles: articles.map((article) => {
        return {
          id: article.id,
          uid: article.uid,
          lang: article.lang,
          data: {
            useArticleSlug: article.data.useArticleSlug,
            category: article.data.category
              ? {
                  id: article.data.category.id,
                }
              : null,
          },
        };
      }),
      categories: categories.map((category) => {
        return {
          id: category.id,
          uid: category.uid,
          lang: category.lang,
          data: {
            name: category.data.name,
          },
        };
      }),
      missions: missions.map((mission) => {
        return {
          id: mission.id,
          uid: mission.uid,
          lang: mission.lang,
          data: {},
        };
      }),

      shared_slices: {
        id: shared_slices.id,
        lang: shared_slices.lang,
        data: {
          header: shared_slices.data.header,
          footer: shared_slices.data.footer,
        },
      },
    },
    events: events.map((event) => {
      return {
        id: event.id,
        uid: event.uid,
        lang: event.lang,
        data: {},
      };
    }),
  };
};

export default App;
