import { COOKIE_CONSENT_CATEGORY, COOKIE_CONSENT_CATEGORIES } from './cookies';

export const DATA_LAYER_NAME = 'dataLayer';

/**
 * The global queue array name: window[DATA_LAYER_QUEUE] in which events will
 * be pushed before consent system is loaded.
 */
export const DATA_LAYER_QUEUE = '_dataLayerQueue';

export const COOKIE_SYSTEM_LOAD_STATE = '_cookieSystemLoadState';
export const ON_COOKIE_SYSTEM_LOAD = '_onCookieConsentUpdate';

const pushEvent = (event) => {
  if (typeof window !== 'undefined') {
    window[DATA_LAYER_NAME] = window[DATA_LAYER_NAME] || [];
    window[DATA_LAYER_NAME].push(event);
  }
};

const drainQueue = () => {
  if (typeof window !== 'undefined') {
    if (typeof window[DATA_LAYER_QUEUE] !== 'undefined') {
      for (const event of window[DATA_LAYER_QUEUE]) {
        pushEvent(event);
      }

      delete window[DATA_LAYER_QUEUE];
    }
  }
};
const drainQueueTimout = () => {
  setTimeout(() => {
    drainQueue();
  }, 1000);
};

const queueEvent = (event) => {
  if (typeof window !== 'undefined') {
    if (typeof window[DATA_LAYER_QUEUE] === 'undefined') {
      window[DATA_LAYER_QUEUE] = [];
    }

    window[DATA_LAYER_QUEUE].push(event);
  }
};

const isCookieConsentGiven = () => {
  if (
    typeof window !== 'undefined' &&
    typeof window.CookieConsentApi !== 'undefined'
  ) {
    const nonNecessaryAcceptedCategories = COOKIE_CONSENT_CATEGORIES.filter(
      (item) => {
        return (
          item !== COOKIE_CONSENT_CATEGORY.NECESSARY &&
          window.CookieConsentApi.allowedCategory(item)
        );
      }
    );

    return nonNecessaryAcceptedCategories.length >= 1;
  }

  return false;
};

export const onCookieConsentUpdate = () => {
  if (typeof window !== 'undefined') {
    if (isCookieConsentGiven()) {
      drainQueueTimout();
    }
  }
};

/**
 * Push event to data layer if possible.
 * Log event in the browser console if in a development environment.
 * @param {Object} event
 */
export const pushDataLayerEvent = (event) => {
  if (typeof window !== 'undefined') {
    window[ON_COOKIE_SYSTEM_LOAD] = onCookieConsentUpdate;

    if (isCookieConsentGiven()) {
      drainQueueTimout();

      pushEvent(event);
    } else {
      queueEvent(event);
    }

    /**
     * Log pushed data layer event in the browser console if one of the
     * following reason:
     *  - In a development environment
     */
    if (process?.env?.NODE_ENV === 'development') {
      console.log('pushDataLayerEvent', event);
    }
  }
};
